/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ZSaveButton from '../system/ZSaveButton';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import axios from 'axios';
import validator from 'validator';
import { login, checkLogin } from '../../store/auth';
import { addMessage } from '../../store/alert';

import { useSelector, useDispatch } from 'react-redux';
import ZAlert from '../system/ZAlert';
import config from 'react-global-configuration';
import { Isset } from '../../utils';
import { getConfig } from '../../store/system';
import { useSetRecoilState } from 'recoil';
import { authTokenState } from '../../recoil/atoms';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(12),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '100%',
		// backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	links: {
		color: 'black',
		//color: theme.palette.primary.main,
	},
}));

export default props => {
	const history = useHistory();
	const dispatch = useDispatch();

	const systemConfig = useSelector(store => store.system.config);
	const logado = useSelector(store => store.auth.logado);
	const message = useSelector(store => store.alert);

	const isLeedRegister = systemConfig.estabelecimentoParametros.find(
		(v, k) => v.parametro_id === 30 && +v.valor === 1
	);

	const isEdmond = systemConfig.hostname === 'edmondbank.com.br';
	const isElo7 = ['www.portal-elo7.com.br', 'portal-elo7.com.br'].includes(systemConfig.hostname);

	const setAuthTokenState = useSetRecoilState(authTokenState);

	useEffect(() => {
		dispatch(checkLogin());
	}, []);

	const classes = useStyles();
	// const {
	// 	// estabelecimentoConfig: { primary_color },
	// } = systemConfig;
	const [email, setEmail] = useState('');
	const [senha, setSenha] = useState('');
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	const handleSubmit = e => {
		// e.preventDefault();
		setLoading(true);
		dispatch(addMessage({ type: 'error', message: '' }));

		let valid = true;
		let arrayError = [];

		if (validator.isEmpty(email)) {
			valid = false;

			arrayError.push('Preencha o e-mail.');
		} else if (!validator.isEmail(email)) {
			valid = false;

			arrayError.push('Insira um e-mail válido.');
		}

		if (validator.isEmpty(senha)) {
			valid = false;
			arrayError.push('Preencha a senha.');
		}

		if (valid) {
			axios
				.post(config.get('apiUrl') + 'login', {
					email: email,
					senha: senha,
				})
				.then(res => res.data)
				.then(async data => {
					if (!data.success) {
						dispatch(addMessage({ type: 'error', message: data.message }));
					}
					if (data.tokenId) {
						setLoading(false);
						await dispatch(addMessage({ type: 'success', message: 'Um codigo foi enviado ao seu email!' }));
						await wait(1200)

						window.location = `/twofa?token=${data.tokenId}`;
						return;
					} else {
						setAuthTokenState(data.usuario.token);
						dispatch(login(data.usuario));

						const check = await dispatch(checkLogin());

						if (Isset(() => check.usuario.estabelecimento.id)) {
							await dispatch(getConfig(check.usuario.estabelecimento.id));
						}
					}

					setLoading(false);
				})
				.catch(err => console.log(err));
		} else {
			setLoading(false);
		}
	};

	const handleShowPasswordClick = () => {
		setShowPassword(prevState => !prevState);
	};

	if (logado) {
		history.push('/');
		// history.push('/cadastrar/estabelecimento');
	}

	return (
		<div style={{ background: systemConfig.backgroundColor }}>
			<Container maxWidth="md" style={{ height: '100vh' }}>
				<Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
					<Grid item xs={12} style={{ background: systemConfig.backgroundColor, padding: 20 }}>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={6} lg={6} style={{}}>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} style={{ padding: 30 }} align="center">
										<Link href={systemConfig.website} color="inherit">
											<img
												src={systemConfig.logoUrl}
												style={{ maxWidth: '100%' }}
												alt={`Logo ${systemConfig.nome}`}
												title={`Logo ${systemConfig.nome}`}
											/>
										</Link>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								lg={6}
								style={{ background: 'white', padding: 25, borderRadius: 5 }}
							>
								<form className={classes.form} noValidate>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="E-mail"
										name="email"
										autoComplete="email"
										autoFocus
										onKeyPress={e => (e.key === 'Enter' ? handleSubmit() : null)}
										onChange={e => {
											setEmail(e.target.value.trim());
										}}
										value={email}
									/>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="password"
										label="Password"
										type={showPassword ? 'text' : 'password'}
										id="password"
										onKeyPress={e => (e.key === 'Enter' ? handleSubmit() : null)}
										onChange={e => {
											setSenha(e.target.value);
										}}
										value={senha}
										autoComplete="current-password"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={handleShowPasswordClick}>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<ZSaveButton
										onClick={e => handleSubmit()}
										color={'primary'}
										align="right"
										// type="submit"
										label={'Login'}
										isEdmond={isEdmond}
										loading={loading}
									/>
									<Grid container style={{ marginTop: 16 }}>
										<Grid item xs>
											<Link
												// onClick={() => history.push('/esqueci-minha-senha')}
												onClick={() => {
													window.location = '/esqueci-minha-senha';
												}}
												style={{ cursor: 'pointer' }}
												variant="body2"
												className={classes.links}
											>
												Esqueceu a senha?
											</Link>
										</Grid>

										{isEdmond && (
											<Grid item>
												<Link
													onClick={() => {
														window.location = 'https://edmond.com.br/cadastro-edmond-pay/';
													}}
													style={{ cursor: 'pointer' }}
													variant="body2"
													className={classes.links}
												>
													{'Cadastre-se'}
												</Link>
											</Grid>
										)}

										{!isEdmond && !isElo7 && (
											<>
												{isLeedRegister && +isLeedRegister.valor ? (
													<Grid item>
														<Link
															onClick={() => {
																window.location = '/pre-cadastro-ec';
															}}
															style={{ cursor: 'pointer' }}
															variant="primary"
															className={classes.links}
														>
															{'Cadastre-se'}
														</Link>
													</Grid>
												) : (
													<Grid item>
														<Link
															// onClick={() => history.push('/cadastrar/estabelecimento')}
															onClick={() => {
																window.location = '/cadastrar/estabelecimento';
															}}
															style={{ cursor: 'pointer' }}
															variant="body2"
															className={classes.links}
														>
															{'Cadastre-se'}
														</Link>
													</Grid>
												)}
											</>
										)}
									</Grid>
								</form>

								<Box mt={5}>
									<Typography variant="body2" color="textSecondary" align="center">
										Sistema de Pagamentos
										<br />
										{`© ${systemConfig.nome} ` + moment().format('YYYY')}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{message.message.length > 0 && (
					<Grid item xs={12}>
						<ZAlert type={message.type} message={message.message} time={moment().unix()} vertical="top" />
					</Grid>
				)}
			</Container>
		</div>
	);
};
