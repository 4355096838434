import addDays from 'date-fns/addDays';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import config from 'react-global-configuration';

import DadosCliente from '../Vendas/DadosCliente';
import { makeStyles } from '@material-ui/core/styles';
import ZTitle from '../system/ZTitle';
import { Paper } from '@material-ui/core';
import { MetodoPagamento } from '../Vendas/FormaPagamento';
import Resumo from '../Vendas/Resumo';
import DescontoBoleto from '../Vendas/DescontoBoleto';
import ConfigurarSplit from '../Vendas/ConfigurarSplit';
// import useClientIp from '../../hooks/useClientIp';

import { Isset } from '../../utils';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { addMessage } from '../../store/alert';
import { useHistory } from 'react-router-dom';
import { isValid } from 'date-fns';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
	novoCarne: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 960,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	content: {
		padding: 10,
	},
}));

// const initialState = {
// 	titulo: null,
// 	formaPagamento: 4,
// 	// Boleto
// 	valorCarne: 0,
// 	parcelasCarne: null,
// 	dataVencimento: addDays(new Date(), 5),
// 	descricao: '',
// 	// Cliente
// 	id: null,
// 	cpf: '',
// 	nome: '',
// 	email: '',
// 	dataNascimento: '',
// 	celular: '',
// 	// Endereco
// 	logradouro: '',
// 	numero: '',
// 	cep: '',
// 	cidade: '',
// 	estado: '',
// 	complemento: '',
// 	chargeProcessingFee: false,
// 	splits: [
// 		{
// 			estabelecimentoId: false,
// 			cpfcnpj: '',
// 			nome: '',
// 			email: '',
// 			value: 0,
// 			tipoSplit: 2,
// 		},
// 	],
// 	descontos: [
// 		{
// 			mode: '',
// 			value: 0,
// 			limitDate: '',
// 		},
// 	],
// };

export default props => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const history = useHistory();
	// const ip = useClientIp();
	const ip = undefined;

	const canAccessCarnes = useSelector(store =>
		Isset(() => store.system.params['31'].valor) ? store.system.params['31'].valor : null
	);
	if (!canAccessCarnes) {
		history.push('/');
	}

	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const estabelecimentoAdmin = useSelector(state => state.admin.estabelecimento);
	const token = useSelector(state => state.auth.token);
	const url = useSelector(store => store.system.config.hostname);

	const estabelecimentoId = Isset(() => estabelecimentoAdmin.id) ? estabelecimentoAdmin.id : estabelecimento.id;

	const [sending, setSending] = useState(false);
	const [data, setData] = useState({
		titulo: null,
		formaPagamento: 4,
		// Boleto
		valorCarne: 0,
		parcelasCarne: null,
		dataVencimento: addDays(new Date(), 5),
		descricao: '',
		// Cliente
		id: null,
		cpf: '',
		nome: '',
		email: '',
		dataNascimento: '',
		celular: '',
		// Endereco
		logradouro: '',
		numero: '',
		cep: '',
		cidade: '',
		estado: '',
		complemento: '',
		chargeProcessingFee: false,
		splits: [
			{
				estabelecimentoId: false,
				cpfcnpj: '',
				nome: '',
				email: '',
				value: 0,
				tipoSplit: 2,
			},
		],
		descontos: [
			{
				mode: '',
				value: 0,
				limitDate: '',
			},
		],
	});
	const [activeStep, setActiveStep] = useState(0);
	const [errors, setErrors] = useState({});
	const [clientes, setClientes] = useState([]);
	const [split, setSplit] = useState();
	const usuario = useSelector(store => store.auth.usuario);
	const canSplit = usuario.can_split;

	const checkSplit = useCallback(async () => {
		const res = await Axios.get(`${config.get('apiUrl')}vendas/${estabelecimentoId}/verify-splits`, {
			headers: { Authorization: `Bearer ${token}` },
		});
		if (res.data.success) {
			setSplit(res.data.splits);
		}
	}, [estabelecimentoId, token]);

	useEffect(() => {
		const getCliente = async () => {
			try {
				const response = await axios.get(`${config.get('apiUrl')}clientes/ultimas-vendas`, {
					headers: { Authorization: `Bearer ${token}` },
				});

				const { success, clientes } = response.data;

				if (success && clientes) {
					setClientes(clientes);
				}
			} catch (error) {
				console.log(error.message);
			}
		};

		getCliente();
		checkSplit();
	}, [token, checkSplit]);
	const validate = step => {
		const e = {};

		if (['all', 0].includes(step)) {
			if (!data.cpf) {
				e.cpf = true;
			}
			if (!data.nome) {
				e.nome = true;
			}
			if (!data.email) {
				e.email = true;
			}

			if (
				!isValid(
					new Date(
						data.dataNascimento
							.split('/')
							.reverse()
							.join('-')
					)
				)
			) {
				e.dataNascimento = true;
			}

			if (!data.logradouro) {
				e.logradouro = true;
			}
			if (!data.numero) {
				e.numero = true;
			}
			if (!data.cep) {
				e.cep = true;
			}
			if (!data.cidade) {
				e.cidade = true;
			}
			if (!data.estado) {
				e.estado = true;
			}
		}

		if (['all', 2].includes(step)) {
			if (!data.titulo) {
				e.titulo = true;
			}
			if (!data.valorCarne || data.valorCarne < 5) {
				e.valorCarne = true;
			}
			if (!data.parcelasCarne) {
				e.parcelasCarne = true;
			}
			if (!data.dataVencimento) {
				e.dataVencimento = true;
			}
		}

		setErrors(e);

		return Object.keys(e).length > 0;
	};

	const changeStep = step => {
		if (step === 0 || step === 1) {
			setActiveStep(0);
		}
		if (step === 2 && !validate(0)) {
			setActiveStep(2);
		}
		if (step === 3 && !validate(2)) {
			setActiveStep(3);
		}
	};

	const handleSubmit = async () => {
		setSending(true);
		if (!validate('all')) {
			let vendaData = {
				estabelecimentoId,
				tipoPagamentoId: data.formaPagamento,
				splits: data.splits,
				valor: data.valorCarne,
				parcelas: data.parcelasCarne,
				diaVencimento: format(data.dataVencimento, 'YYYY-MM-DD'),
				titulo: data.titulo,
				descricao: data.descricao,
				descontos: data.descontos,
			};

			const newSplits = vendaData.splits.map((value, key) => {
				return { ...value, chargeProcessingFee: true }; //data.chargeProcessingFee };
			});

			vendaData.splits = newSplits;

			if (Isset(() => data.id) && data.id !== null) {
				vendaData.clienteId = data.id;
			}
			vendaData = {
				...vendaData,
				cliente: {
					nome: data.nome,
					cpf: data.cpf,
					dataNascimento: format(parse(data.dataNascimento, 'DD/MM/YYYY', new Date()), 'YYYY-MM-DD'),
					email: data.email,
					celular: data.celular,
				},
				endereco: {
					logradouro: data.logradouro,
					numero: data.numero,
					cep: data.cep,
					cidade: data.cidade,
					estado: data.estado,
					complemento: data.complemento,
					bairro: data.bairro
				},
				ip,
			};

			try {
				const result = await axios.post(`${config.get('apiUrl')}carnes`, vendaData, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (result.data.success) {
					dispatch(
						addMessage({
							type: 'success',
							message: result.data.message ? result.data.message : 'Operação realizada com sucesso',
						})
					);
					history.push(`/carnes`);
				} else {
					dispatch(addMessage({ type: 'error', message: result.data.error }));
				}
			} catch (error) {
				console.log('error ' + error);
				console.log('status ' + error.response.status);
				console.error(error);
				if (error.response && error.response.status === 504) {
					dispatch(
						addMessage({
							type: 'error',
							message:
								'Timeout: excedeu o limite de tempo, por favor tente novamente ou contate o suporte',
						})
					);
				} else if (error.response.status === 502) {
					dispatch(
						addMessage({
							type: 'error',
							message:
								'Erro ao se comunicar com api , por favor tente novamente caso persista favor contatar o suporte ',
						})
					);
				} else {
					dispatch(
						addMessage({
							type: 'error',
							message: error.response.message
								? error.response.message
								: `Algo inesperado ocorreu contate o suporte
								code: ${error.response.status}`,
						})
					);
				}
			} finally {
				setSending(false);
			}
		}
	};

	return (
		<div className={classes.novoCarne}>
			<ZTitle title="Novo Carnê" marginBottom={40} />

			<Paper className={classes.paper}>
				<div className={classes.content}>
					{activeStep === 0 && (
						<DadosCliente
							clientes={clientes}
							data={data}
							setData={setData}
							errors={errors}
							changeStep={changeStep}
						/>
					)}

					{activeStep === 2 && (
						<MetodoPagamento
							data={data}
							setData={setData}
							errors={errors}
							changeStep={changeStep}
							setActiveStep={setActiveStep}
						/>
					)}

					{activeStep === 3 && (
						<DescontoBoleto
							data={data}
							carne={true}
							setData={setData}
							errors={errors}
							setErrors={setErrors}
							setActiveStep={setActiveStep}
							canSplit={canSplit}
							split={split}
						/>
					)}

					{activeStep === 4 && (
						<ConfigurarSplit
							data={data}
							setData={setData}
							estabelecimentoId={estabelecimentoId}
							errors={errors}
							setErrors={setErrors}
							changeStep={changeStep}
							setActiveStep={setActiveStep}
						/>
					)}

					{activeStep === 5 && (
						<Resumo
							data={data}
							handleSubmit={handleSubmit}
							sending={sending}
							activeStep={activeStep}
							setActiveStep={setActiveStep}
							url={url}
							split={split}
							canSplit={canSplit}
						/>
					)}
				</div>
			</Paper>
		</div>
	);
};
